import React from 'react'
import Layout from '../../components/layout'

import kurzprozess from '../../images/info/generator.jpg'

const IndexPage = () => (
  <Layout>
    <section id="" className="section-wrapper">
      <header className="section-header">
        <h3>Der HR-Kurzprozess</h3>
        <h5>Zeugnisse erstellen ohne Mitarbeit von Vorgesetzten und Mitarbeitenden</h5>
      </header>
      <div>
        <p>Grundsätzlich soll ZEP Mitarbeitende, Vorgesetzte und Personalabteilung vernetzen und den Prozess der Zeugniserstellung firmenweit optimieren.</p>
        <p>
          Zusätzlich zu diesem Grundgedanken kann ZEP nur in der Personalabteilung eingesetzt werden. Eine HR-Abteilung kann mit ZEP ohne Mitwirken Dritter
          innert weniger Minuten Zeugnisse generieren und ausliefern. Der ZEP HR-Kurzprozess beinhaltet jene Prozessschritte, die für das Generieren und
          Ausdrucken eines optimalen Zeugnisses benötigt werden. Der HR-Kurzprozess ist Personalabteilungen vorbehalten.
        </p>
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <img src={kurzprozess} alt="kurzprozess" />
      </div>
    </section>
  </Layout >
)

export default IndexPage
